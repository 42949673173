export class Avatar {};

export class Title {};

export class Text {};

export class Logo {};

export class Image {};

export class Quote {};

export class Link {};

export class ClassList {};

export class Button {};

export class Grid { };
