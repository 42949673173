<template>
  <section
    v-styler="$sectionData.classes"
    ref="sizechart"
    :class="$sectionData.classes"
    data-v-step="2"
    class="size-chart size-chart--border">
    <div
      id="filter"
      class="no-filter"/>
    <div class="size-chart__top-region">
      <h1
        v-styler="title"
        class="apply-font-heading"
        data-v-step="3"
        v-html="title"/>
      <h2
        v-styler="brandModel"
        class="apply-font-subheading"
        v-html="brandModel"/>
      <h3
        v-styler="brandDescription"
        class="apply-font-description"
        v-html="brandDescription"/>
    </div>
    <div class="size-chart__middle-region">
      <div
        v-styler:block="chart"
        class="size-chart__column size-chart__table-wrapper apply-font-chart">
        <table
          class="size-chart__chart"
          data-v-step="5">
          <tbody>
            <tr>
              <th
                v-for="(key, i) in Object.keys(brandData.sizes[0])"
                :key="key+i"
                contenteditable="true"
              >
                {{ key }}
              </th>
            </tr>
            <tr
              v-for="(size, i) in brandData.sizes"
              :key="size+i"
            >
              <td contenteditable="true">{{ size.size.toUpperCase() }}</td>
              <td
                contenteditable="true"
                v-html="numberToFractionHtml(size.chest)"/>
              <td
                contenteditable="true"
                v-html="numberToFractionHtml(size.length)"/>
            </tr>
          </tbody>
        </table>
        <p contenteditable="true"><em>* Measurements are in inches</em></p>
      </div>
      <div class="size-chart__column">
        <uploader
          :path="brandMockup"
          data-v-step="4"
          class="size-chart__mockup" />
      </div>
    </div>
    <DraggableDiv>
      <template slot="header">
        <uploader
          v-styler:logo="logo"
          ref="logo"
          data-v-step="6"
          image-id="logo"
          mode=""
          path="./img/logo.svg"
          class="size-chart__logo move" />
      </template>
    </DraggableDiv>
    <div class="initial-picker">
      <div
        id="font-picker-heading"
      />
    </div>
    <div class="initial-picker">
      <div
        id="font-picker-subheading"
      />
    </div>
    <div class="initial-picker">
      <div
        id="font-picker-description"
      />
    </div>
    <div class="initial-picker">
      <div
        id="font-picker-chart"
      />
    </div>
  </section>
</template>

<script>
import FontPicker from 'font-picker';
import DraggableDiv from '../../../src/components/DraggableDiv';
import * as types from '../../../src/types';
import { bus } from '../../app';

export default {
  name: 'SizeChart',
  cover: 'img/covers/hero1.png',
  group: 'sizechart',
  components: {
    DraggableDiv
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  $schema: {
    button: types.Button,
    classes: types.ClassList
  },
  data () {
    return {
      title: 'Size Chart',
      brandData: this.$builder.brandData,
      brandModel: `${this.$builder.brandData.brand} ${this.$builder.brandData.model}`,
      brandMockup: this.$builder.brandData.image,
      brandDescription: this.$builder.brandData.description
    }
  },
  created () {
    bus.$on('brand-updated', data => {
      this.brandData = data;
      this.brandModel = `${data.brand} ${data.model}`;
      this.brandDescription = data.description;
    });
  },
  mounted () {
    const googleFontsApiKey = 'AIzaSyC-oNuLfO-YzRr-G7bSJ5PqopnBOrYV3-I';

    // Initialize font pickers
    try {
      // heading font picker
      new FontPicker(
        googleFontsApiKey,
        'Great Vibes',
        {
          pickerId: 'heading',
          categories: ['display', 'handwriting'],
          sort: ['popularity'],
          limit: 200
        }
      );

      // brand/model font picker
      new FontPicker(
        googleFontsApiKey,
        'Raleway',
        {
          pickerId: 'subheading',
          sort: ['popularity'],
          limit: 200
        }
      );

      // size chart font picker
      new FontPicker(
        googleFontsApiKey,
        'Raleway',
        {
          pickerId: 'description',
          sort: ['popularity'],
          limit: 200
        }
      );

      // size chart font picker
      new FontPicker(
        googleFontsApiKey,
        'Raleway',
        {
          pickerId: 'chart',
          sort: ['popularity'],
          limit: 200
        }
      );
    } catch (error) {

    }

    // wire up font pickers
    const fontPickerElements = document.querySelectorAll('.initial-picker');
    if (fontPickerElements.length > 0) {
      document.querySelectorAll('.styler-list.options li.text-font').forEach((el, index) => {
        const newParent = el;
        const oldParent = fontPickerElements[index];

        if (oldParent && oldParent.childNodes) {
          while (oldParent.childNodes.length > 0) {
            newParent.appendChild(oldParent.childNodes[0]);
          }
        }
      })
    }

    // disable right-click
    // if (process.env.NODE_ENV === 'production') {
    //   document.querySelector('.size-chart').addEventListener('contextmenu', event => event.preventDefault());
    // }

    // skip tour
    if (window.localStorage.getItem('seenTour') !== 'true') {
      this.$tours['App'].start();

      const observer = new MutationObserver(mutationsList => {
        mutationsList.forEach(function (mutation) {
          mutation.addedNodes.forEach(function (addedNode) {
            if (addedNode.querySelectorAll('.v-step__button-skip').length > 0) {
              document.querySelectorAll('.v-step__button-skip').forEach(el => {
                el.addEventListener('click', () => {
                  window.localStorage.setItem('seenTour', 'true');
                  observer.disconnect();
                });
              });
            }

            if (addedNode.querySelectorAll('.v-step__button-stop').length > 0) {
              document.querySelectorAll('.v-step__button-stop').forEach(el => {
                el.addEventListener('click', () => {
                  window.localStorage.setItem('seenTour', 'true');
                  observer.disconnect();
                });
              });
            }
          });
        });
      });

      observer.observe(document.body, { subtree: true, childList: true });
    }

    // emit event reporting element being edited
    this.$refs.sizechart.addEventListener('click', e => bus.$emit('editing-element', e.target), true);
  },
  methods: {
    numberToFractionHtml (n) {
      const acceptableDenominators = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      const maxDistanceToNumerator = 0.0001;
      const negative = (n < 0);
      if (negative) n = -n;

      const wholePart = Math.floor(n);
      n -= wholePart;

      const denom = acceptableDenominators.find(d =>
        Math.abs(d * n - Math.round(d * n)) <= maxDistanceToNumerator
      );
      if (typeof denom === 'undefined') {
        return;
      }
      const numer = Math.round(denom * n);

      if (denom === 1) {
        return '' + (wholePart + numer) * (negative ? -1 : 1);
      }

      return (negative ? '-' : '') +
          (wholePart ? wholePart + ' ' : '') +
          `<span class="fraction">
            <sup>${numer}</sup>⁄<sub>${denom}</sub>
          </span>`;
    }
  }
};
</script>

<style scoped>
  .size-chart {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    font-family: 'Raleway', Arial, Helvetica, sans-serif;
    margin: 100px 0;
    min-height: 650px;
    height: 100%;
    width: 1000px;
    align-items: center;
    padding: 50px;
    position: relative;
    overflow: hidden;
  }

  .size-chart--border {
    border: 1px dashed #333;
  }

  .no-filter {
    background: var(--background-image-url) no-repeat var(--background-image-align);
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .greyscale {
    background: var(--background-image-url) no-repeat var(--background-image-align);
    background-size: cover;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .blur {
    background: var(--background-image-url) no-repeat var(--background-image-align);
    background-size: cover;
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .contrast {
    background: var(--background-image-url) no-repeat var(--background-image-align);
    background-size: cover;
    -webkit-filter: contrast(150%);
    -moz-filter: contrast(150%);
    -o-filter: contrast(150%);
    -ms-filter: contrast(150%);
    filter: contrast(150%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .size-chart h1 {
    color: var(--heading-text-color);
    font-size: 85px;
    line-height: normal;
    margin-bottom: 25px;
  }

  .size-chart h2 {
    color: var(--subheading-text-color);
    font-size: 35px;
    line-height: normal;
    margin-bottom: 10px;
  }

  .size-chart h3 {
    color: var(--description-text-color);
    font-size: 24px;
    line-height: normal;
    margin-bottom: 25px;
  }

  .size-chart__table-wrapper {
    color: var(--chart-text-color);
    background-color: var(--chart-bg-color);
    cursor: pointer;
    height: 100%;
    padding: 30px 0;
  }

  .size-chart table {
    border-spacing: unset;
    color: var(--chart-text-color);
    text-align: center;
  }

  .size-chart th {
    border-bottom: 1px solid var(--chart-text-color);
    font-size: 24px;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 10px;
    width: 125px;
    position: relative;
  }

  .size-chart td {
    font-size: 22px;
    padding: 5px 0;
    border-bottom: 1px solid var(--chart-text-color);
    position: relative;
  }

  .size-chart__top-region {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    z-index: 100;
    position: relative;
  }

  .size-chart__middle-region {
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 100;
    position: relative;
  }

  .size-chart__bottom-region {
    display: flex;
    width: 100%;
    z-index: 100;
    position: relative;
  }

  .size-chart__column {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }

  .size-chart__mockup {
    max-width: 350px;
    width: 100%;
  }

  .size-chart__logo.is-editable:hover {
    box-shadow: none;
  }

  [contenteditable="true"] {
    cursor: text;
  }

   @media only screen and (max-width: 999px) {
    .size-chart {
      width: 320px;
      padding: 25px;
    }

    .size-chart__mockup {
      margin-bottom: 25px;
    }

    .size-chart__middle-region {
      flex-direction: column-reverse;
      align-items: center;
    }

    .size-chart__column {
      width: 100%;
    }

    .size-chart__table-wrapper {
      margin-bottom: 75px;
    }
  }

   @media only screen and (max-width: 550px) {
    .size-chart {
      margin-top: 150px;
    }
  }
</style>
