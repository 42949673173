<template>
  <div class="editor-container">
    <nav
      :class="{ 'is-visible': this.$builder.isEditing }"
      class="nav">
      <a
        href="/"
        style="width: 214px;"><img
          src="../../sizechart/img/logo.svg"
          alt="Sizechart.io"></a>
      <div class="nav-options">
        <model-select
          :options="brandOptions"
          v-model="brand"
          data-v-step="0"
          placeholder="Select a brand/model..."/>
        <model-select
          :options="themeOptions"
          v-model="theme"
          data-v-step="1"
          style="margin-left: 0; margin-right: auto;"
          placeholder="Select a background image..."/>
        <button
          id="download-button"
          :class="{ 'is-visible': $builder.isEditing }"
          class="controller-button is-green"
          tooltip-position="top"
          @click="$refs.donate.openModal(true)">
          <span id="download-button-text">Download</span>
          <VuseIcon name="download"/>
        </button>
        <Modal ref="donate">
          <template #headerContent>
            <h3>🌟 Support Sizechart.io! 🌟</h3>
          </template>
          <template #bodyContent>
            <p>Hi, I'm Matt 👋. Thank you for using Sizechart.io. I am thrilled to offer you a free tool to craft and download size charts customized to you and your brand! Your support helps me host it, keep it running smoothly and add exciting new features!</p>
            <p><strong>Why Donate?</strong></p>
            <ul style="margin-top: 0">
              <li><strong>Keep It Free:</strong> Help me cover domain and hosting costs to keep the lights on. 💡</li>
              <li><strong>Fuel Innovation:</strong> Your support helps me enhance the tool and add more features. 🎉</li>
              <li><strong>Show Your Support:</strong> Every contribution makes a difference. Big or small. 🙏</li>
            </ul>
            <p>Click 'Donate' to support my mission to keep this tool free for everyone!</p>
            <div id="donate-button-container">
              <div id="donate-button"/>
              <!-- eslint-disable vue/html-quotes -->
              <VueScriptComponent script='<script charset=UTF-8 src=https://www.paypalobjects.com/donate/sdk/donate-sdk.js></script><script>PayPal.Donation.Button({env:"production",hosted_button_id:"SRRJX899PPG5S",image:{src:"https://pics.paypal.com/00/s/ZmQyOTM5YjYtZWI2Ny00NTUyLTlmNTctNGYwM2ZiMmM4ODQ2/file.JPG",alt:"Donate with PayPal button",title:"PayPal - The safer, easier way to pay online!"}}).render("#donate-button")</script>'/>
              <!--eslint-enable-->
              <p style="display: flex; align-items: center; margin-top: 12px;"><a
                href="#"
                @click="submit">Download my size chart</a><VuseIcon name="download"/></p>
            </div>
          </template>
        </Modal>
      </div>
    </nav>
    <ul
      ref="menu"
      :class="{ 'is-visible': this.$builder.isEditing }"
      class="menu">
      <img
        src="../../sizechart/img/intro-gradient.svg"
        alt="Intro background gradient">
      <br>
      <div
        class="menu-header"
        style="cursor: default;">
        Brand/Model
      </div>
      <!-- <div class="menu-group">
          <model-select
            :options="brandOptions"
            v-model="brand"
            placeholder="Search brand/model"/>
        </div> -->
      <br>
      <li
        class="menu-group">
        <div
          class="menu-header"
          @click="toggleGroupVisibility">
          <span class="menu-title">Heading</span>
          <span class="menu-icon">
            <VuseIcon name="arrowDown"/>
          </span>
        </div>
        <div class="menu-body">
          <template>
            <p>Font</p>
            <!-- <div id="font-picker-heading"/> -->
            <br>
            <br>
          </template>
        </div>
      </li>
      <li
        class="menu-group">
        <div
          class="menu-header"
          @click="toggleGroupVisibility">
          <span class="menu-title">Subheading</span>
          <span class="menu-icon">
            <VuseIcon name="arrowDown"/>
          </span>
        </div>
        <div class="menu-body">
          <template>
            <p>Font</p>
            <!-- <div id="font-picker-brand"/> -->
            <br>
            <br>
          </template>
        </div>
      </li>
      <li
        class="menu-group">
        <div
          class="menu-header"
          @click="toggleGroupVisibility">
          <span class="menu-title">Measurements Table</span>
          <span class="menu-icon">
            <VuseIcon name="arrowDown"/>
          </span>
        </div>
        <div class="menu-body">
          <template>
            <p>Font</p>
            <!-- <div id="font-picker-chart"/> -->
            <br>
            <br>
          </template>
        </div>
      </li>
      <li
        class="menu-group">
        <div
          class="menu-header"
          @click="toggleGroupVisibility">
          <span class="menu-title">Mockup</span>
          <span class="menu-icon">
            <VuseIcon name="arrowDown"/>
          </span>
        </div>
        <div class="menu-body">
          <template>
            Border
          </template>
        </div>
      </li>
    </ul>
    <div class="artboard-container">
      <div
        id="artboard"
        ref="artboard"
        :class="{ 'is-sorting': $builder.isSorting, 'is-editable': $builder.isEditing }"
        class="artboard">
        <component
          v-for="section in $builder.sections"
          :is="section.name"
          :key="section.id"
          :id="section.id"/>
      </div>
    </div>
    <footer class="footer-links-container">
      <ul>
        <li
          style="cursor: pointer">
          <a
            href="privacy.html"
            target="_blank"
            style="color: #000">Privacy Policy</a>
        </li>
        <li
          style="cursor: pointer"
          @click="$refs.reportBug.openModal()">
          Report a bug
        </li>
      </ul>
    </footer>
    <Modal ref="reportBug">
      <template #headerContent>
        <h2>Report a bug</h2>
      </template>
      <template #bodyContent>
        <form
          name="issue"
          netlify>
          <p>
            <label>Name <input
              type="text"
              name="name"
              required></label>
          </p>
          <p>
            <label>Email <input
              type="email"
              name="email"
              required></label>
          </p>
          <br>
          <p style="display: flex; flex-direction: column;">
            <label for="issue">What issue are you experiencing?</label>
            <textarea
              id="issue"
              name="issue"
              rows="5"
              cols="33"
              required/>
          </p>
          <button
            class="controller-button is-visible is-green"
            type="submit">
            Report Bug
          </button>
        </form>
      </template>
    </Modal>
  </div>
</template>

<script>
import Vue from 'vue';
import Sortable from 'sortablejs';
import VuseIcon from './VuseIcon';
import Modal from './Modal';
import { VueTyper } from 'vue-typer'
import { ModelSelect } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';
import brandsData from '../../sizechart/data/brands.json';
import themesData from '../../sizechart/data/themes.json';
import { bus } from '../../sizechart/app';
import VueScriptComponent from 'vue-script-component'

export default {
  name: 'VuseBuilder',
  components: {
    VueTyper,
    VuseIcon,
    ModelSelect,
    Modal,
    VueScriptComponent
  },
  props: {
    showIntro: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: () => ({
        title: '',
        sections: []
      })
    }
  },
  data () {
    return {
      title: null,
      listShown: false,
      tempSections: null,
      sections: this.getSections(),
      currentSection: '',
      groups: {},
      brandsData,
      brandOptions: this.mapBrandsToOptions(brandsData),
      brand: {
        value: '',
        text: ''
      },
      themeOptions: this.mapThemesToOptions(themesData),
      theme: {
        value: '',
        text: ''
      }
    }
  },

  watch: {
    title (value) {
      this.$builder.title = value;
      document.title = value;
    },
    brand (value) {
      bus.$emit('brand-updated', brandsData[value.value]);
    },
    theme (value) {
      bus.$emit('theme-updated', themesData[value.value]);
    }
  },
  created () {
    // sets the initial data.
    this.$builder.isEditing = true;
    this.title = this.$builder.title;
    this.themes = this.$builder.themes;
    this.generateGroups();

    // Set builder
    Vue.nextTick(() => {
      this.addTheme(this.$builder.themes[0])
    });
  },
  mounted () {
    this.$builder.rootEl = this.$refs.artboard;
    const groups = this.$refs.menu.querySelectorAll('.menu-body');
    const _self = this;
    groups.forEach((group) => {
      Sortable.create(group, {
        group: {
          name: 'sections-group',
          put: false,
          pull: 'clone'
        },
        sort: false
      });
    });
    this.sortable = Sortable.create(this.$refs.artboard, {
      group: {
        name: 'artboard',
        put: 'sections-group'
      },
      animation: 150,
      scroll: true,
      scrollSpeed: 10,
      sort: false,
      disabled: true,
      preventOnFilter: false,
      onAdd (evt) {
        _self.addSection(_self.currentSection, evt.newIndex);
        evt.item.remove();
      },
      onUpdate (evt) {
        _self.$builder.sort(evt.oldIndex, evt.newIndex);
      }
    });
  },
  updated () {
    if (this.$builder.scrolling) {
      this.$builder.scrolling(this.$refs.artboard);
    }
  },
  beforeDestroy () {
    this.$builder.clear();
  },
  methods: {
    newSection () {
      // add the section immediatly if none are present.
      if (this.sections.length === 1) {
        this.addSection(this.sections[0]);
        return;
      }
      this.toggleListVisibility();
    },
    addSection (section, position) {
      this.$builder.add(section, position);
    },
    clearSections () {
      this.tempSections = this.$builder.clear();
      setTimeout(() => {
        this.tempSections = null;
      }, 5000);
    },
    undo () {
      this.$builder.sections = this.tempSections;
      this.tempSections = null;
    },
    addTheme (theme) {
      this.$builder.isEditing = true;
      this.$builder.set(theme);

      // Set default brand and theme
      // Vue.nextTick(() => {
      //   this.brand = {
      //     value: 3
      //   }

      //   this.theme = {
      //     value: 0
      //   }
      // });
    },
    toggleSort () {
      this.$builder.isSorting = !this.$builder.isSorting;
      this.$builder.isEditing = !this.$builder.isSorting;
      if (!this.$builder.isSorting && this.sortable) {
        this.sortable.option('sort', false);
        this.sortable.option('disabled', true);
        return;
      }
      this.sortable.option('disabled', false);
      this.sortable.option('sort', true);
    },
    toggleListVisibility () {
      this.listShown = !this.listShown;
      this.sortable.option('disabled', !this.listShown);
    },
    showList () {
      this.listShown = true;
      this.sortable.option('disabled', false);
    },
    hideList () {
      this.listShown = false;
      this.sortable.option('disabled', true);
    },
    toggleGroupVisibility (e) {
      const element = e.target;
      const group = element.closest('.menu-group');
      group.classList.toggle('is-visible');
    },
    submit () {
      this.$emit('saved', this.$builder);
    },
    generateGroups () {
      let groups = { random: [] };

      // group sections together
      this.sections.forEach((section) => {
        let sectionGroup = section.group;
        if (!sectionGroup) {
          groups.random.push(section);
          return;
        }
        if (!groups[sectionGroup]) {
          groups[sectionGroup] = [section];
          return;
        }
        groups[sectionGroup].push(section);
      })
      this.groups = groups;
    },
    getSections () {
      let sections = [];

      // get sections data
      sections = Object.keys(this.$builder.components).map((sec) => {
        return {
          name: sec,
          group: this.$builder.components[sec].options.group,
          cover: this.$builder.components[sec].options.cover,
          schema: this.$builder.components[sec].options.$schema
        }
      });
      return sections;
    },
    mapBrandsToOptions (data) {
      return data.map((val, i) => {
        return {
          value: i,
          text: `${val.brand} ${val.model}`
        }
      }).sort((a, b) => a.text !== b.text ? a.text < b.text ? -1 : 1 : 0)
    },
    mapThemesToOptions (data) {
      return data.map((val, i) => {
        return {
          value: i,
          text: val.name
        }
      }).sort((a, b) => a.text !== b.text ? a.text < b.text ? -1 : 1 : 0)
    }
  }
};
</script>

<style lang="stylus">
@import '../stylus/_app.styl'

.nav
  display: none
  height: 70px
  justify-content: space-between
  padding: 15px 25px
  position: fixed
  background-color: #ffffff
  box-shadow: 0 0 10px alpha($dark, 20%)
  z-index: 105
  width: 100%

  &.is-visible
    display: flex
  .nav-options
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%

@media only screen and (max-width: 1000px)
  .nav
    height: auto
    align-items: center
    img
      width: 175px
    .ui.selection.dropdown
      margin-right: 25px !important
  #download-button
    width: 70px
  #download-button-text
    display: none
  .controller-intro__divider-container__content ul
    flex-direction: column
    li
      justify-content: center
      margin-bottom: 25px

@media only screen and (max-width: 550px)
  .ui.selection.dropdown>.dropdown.icon
    margin: 0 !important
    padding: 0 !important
  .nav
    flex-direction: column
    .ui.selection.dropdown
      margin: 15px 15px 0 0 !important
      width: 100% !important
    .controller-button
      margin-top: 15px
      padding: 10px 15px
      justify-content: center !important

.editor-container
  display: flex
  height 100%
.artboard-container
  align-items: center
  display: flex
  flex-direction: column
  overflow-y: scroll
  height: 100%
  width: 100%
  position: relative
.artboard:empty
  display: none
.artboard
  display: flex
  flex-direction: column
  align-items: center
  position: absolute
  &.is-editable
    outline: none
    &:hover
      box-shadow: none
.controller
  box-sizing: border-box
  width: 100%
  position: relative
  &-panel
    position: fixed
    z-index: 200
    top: 25px
    right: 25px
  &-input
    outline: none
    border: 1px solid $gray
    padding: 0.5em 1em
    margin: 20px 0
    border-radius: 40px
    width: 100%
    font-size: 16px
    &:focus
      border-color: $blue
      box-shadow: 0 0 0 2px alpha($blue, 50%)
  &-button
    display: none
    transition: 0.2s
    border: none
    outline: none
    border-radius: 50px
    padding: 10px 25px
    color: $white
    fill: $white
    font-size: 16px
    white-space: nowrap
    svg
      transition: 0.2s
    &:not(:last-child)
      margin-right: 20px
    &.is-rotated >svg
      transform: rotate(45deg)
    &:hover
      @extends $floatHover
    &.is-blue
      background-color: $blue
      &:hover
        background-color: darken($blue, 20%)
    &.is-red
      background-color: $red
      &:hover
        background-color: darken($red, 20%)
    &.is-green
      background: linear-gradient(65deg, #01BA9F 0, #72DDCD 100%)
      background-origin: border-box
      &:hover
        background-color: darken($green, 20%)
    &.is-dark
      background-color: $dark
      &:hover
        background-color: darken($dark, 20%)
    &.is-gray
      background-color: $gary
      &:hover
        background-color: darken($gray, 20%)
    &.is-visible
      display: flex
      align-items: center
      justify-content: space-between
    &:disabled
      background: #ccc
      cursor: not-allowed
      &:hover
        background: #ccc
        cursor: not-allowed
  &-intro
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    padding: 70px 50px
    font-family: 'Raleway', Arial, Helvetica, sans-serif
    &::after
      content: ''
      position: absolute
      width: 100%
      max-width: 100%
      height: 920px
      background-image: url('https://www.sizechart.io/assets/intro-gradient.svg')
      background-repeat: no-repeat
      background-size: auto
      left: 50%
      top: 0
      background-position: center top
      -webkit-transform: translate(-50%)
      transform: translate(-50%)
      z-index: -1
    &__top-content
      width: 100%
      margin: 75px 0 50px 0
      text-align: center
      font-size: 24px
      color: $dark
    &__divider-container
      display: flex
      width: 100%
      justify-content: center
      margin-top: -5px
      z-index: 1
      &__content
        display: flex
        justify-content: center
        padding: 50px
        width: 100%
        max-width: 1100px
        border-top: 1px solid #e2e2e2
        background: #ffffff
        ul
          list-style: none
          display: flex
          margin: 0
          padding: 0
          justify-content: space-between
          width: 100%
          li
            align-items: center
            display: flex
            font-size: 20px
            font-weight: bold
    h1
      color: #fff
      font-size: 36px
      font-weight: bolder
      font-family: 'Raleway', Arial, Helvetica, sans-serif
    .logo
      max-width: 250px
      position: absolute
      top: 20px
      left: 30px
    .controller-theme
      max-width: 400px
      color: #fff

  &-themes
    display: flex
    align-items: center
    flex-direction: column
    width: 100%

  &-theme
    background: linear-gradient(65deg, #01BA9F 0, #72DDCD 100%)
    background-origin: border-box
    color: #ffffff
    transition: 0.2s
    border: none
    outline: none
    margin: 25px 0
    padding: 20px
    border-radius: 50px
    width: 100%
    cursor: pointer
    font-size: 22px
    outline: none
    font-family: 'Raleway', Arial, Helvetica, sans-serif
    font-weight: bold
    &:hover
      box-shadow: 0 14px 28px darkslategrey, 0 10px 10px darkslategrey
    &:disabled
      background-color: #ccc
      cursor: not-allowed
.menu
  margin: 0
  user-select: none
  -moz-user-select: none
  width: 20%
  max-width: 300px
  background: $white
  padding: 20px 10px
  display: none
  flex-direction: column
  list-style: none
  box-shadow: 1px 0 10px alpha($dark, 20%)
  ~/-header
    cursor: pointer
  &.is-visible
    display: none
  &-body
    display: none
    position: relative
    padding: 0
    margin: 0
    list-style: none
    ~/-group &
      width: 90%
      margin: 10px auto
    ~/-group.is-visible &
      display: block
  &-icon
    width: 24px
    height: 24px
    fill: $gray
    transition: 0.2s
    ~/-group.is-visible &
      transform: rotate(180deg)

  &-element
    position: relative
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    min-height: 50px
    border-radius: 5px
    background: darken($gray, 10%)
    transition: 0.3s
    cursor: pointer
    color: $white
    overflow: hidden
    user-select: none
    -moz-user-select: none
    &:not(:last-child)
      margin-bottom: 10px
    &:hover
      @extends $floatHover

  &-elementImage
    max-width: 100%
    pointer-events: none
    +
      ~/-elementTitle
        position: absolute
        right: 0
        bottom: 0
        left: 0
        text-shadow: 1px 1px 2px alpha($black, 80%)
        text-align: center
        padding: 5px

  &-header
    display: flex
    justify-content: space-between
    align-items: center
    padding: 10px 5px
    border-bottom: 1px solid alpha($black, 5%)

.sortable-ghost
  opacity: 0.3
  box-shadow: 0 0 2px 1px $blue

.is-editable
  &:hover
    box-shadow: inset 0 0 0 2px $blue

.fraction > sup,
.fraction > sub
  font-size .66em

.fraction
  font-size: 14px
  position: absolute
  top: 10px
  margin-left: 2px
  & > sup
    vertical-align .4em
  & > sub
    vertical-align -.2em

.ui.fluid.dropdown
  display: flex !important
  align-items: center
  width: 100% !important
  max-width: 400px
  margin: 0 25px

.ui.selection.dropdown>.dropdown.icon
  top: unset !important

input.search
  height: 100%

.footer-links-container
  display: none
  position: fixed
  bottom: 0
  font-size: 14px
  width: 100%
  background: #ffffff
  box-shadow: 0 0 10px alpha($dark, 20%)
  padding: 5px 25px
  z-index: 205
  ul
    display: flex
    list-style: none
    margin: 0
    padding: 0
    li
      margin-right: 15px
.is-visible + .artboard-container + .footer-links-container
    display: flex

.is-visible + .artboard-container
  background-color: #ffffff

.emoji
  font-size: 35px
  margin-right: 15px

#donate-button-container
  display: flex
  flex-direction: column
  align-items: center
  margin: 16px 0
  #donate-button
    width: 300px
</style>
