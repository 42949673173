<template>
  <div id="app">
    <div id="notification-banner">
      <h5>Browser Compatibility</h5>
      <p>Some features are not available on a mobile device. For the best experience, use Google Chrome or Safari.</p>
    </div>
    <VuseBuilder
      v-if="window.location.pathname.includes('builder')"
      @saved="downloadImage" />
    <Home v-else/>
    <v-tour
      :steps="steps"
      name="App"/>
  </div>
</template>

<script>
import html2canvas from 'html2canvas';
import { bus } from '../sizechart/app';

export default {
  name: 'App',
  data () {
    return {
      window: document.defaultView,
      filename: 'Bella+Canvas_3001',
      steps: [
        {
          target: '[data-v-step="0"]',
          header: {
            title: 'Hi, have a quick look around.'
          },
          content: 'Search and select a T-Shirt brand/model above.'
        },
        {
          target: '[data-v-step="1"]',
          content: 'Search and select a theme above.'
        },
        {
          target: '[data-v-step="2"]',
          params: {
            placement: 'right'
          },
          content: 'Click on the Background to add a custom background color or background image.'
        },
        {
          target: '[data-v-step="3"]',
          content: 'Click on the Heading, Subheading, Description and Chart to stylize their text.'
        },
        {
          target: '[data-v-step="4"]',
          content: 'Click on the T-shirt mockup to upload your own custom image.'
        },
        {
          target: '[data-v-step="5"]',
          content: 'Click the size, chest and length values to enter your own custom measurements.'
        },
        {
          target: '[data-v-step="6"]',
          content: 'Click on the sizechart.io logo to upload your own logo and drag it to position it.'
        }
      ]
    }
  },
  created () {
    document.addEventListener('DOMContentLoaded', function () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        // true for mobile device
        document.getElementById('notification-banner').style.display = 'flex';
      }
    });

    bus.$on('brand-updated', data => {
      this.filename = this.buildFilename(data);
    });
  },
  methods: {
    downloadImage () {
      document.querySelector('.artboard-container').scrollTop = 0;
      const sizechart = document.querySelector('.size-chart');
      sizechart.classList.remove('size-chart--border');

      const downloadButton = document.getElementById('download-button');
      const downloadButtonText = document.getElementById('download-button-text');
      downloadButton.disabled = true;
      downloadButton.style.cursor = 'not-allowed';
      downloadButtonText.innerText = 'Downloading...';

      html2canvas(sizechart, {
        allowTaint: true,
        useCORS: true,
        scale: 5,
        scrollX: -window.scrollX,
        scrollY: -window.scrollY,
        height: sizechart.offsetHeight - 2,
        width: sizechart.offsetWidth - 2
      })
        .then(canvas => {
          this.saveAs(canvas.toDataURL(), `${this.filename}_sizechart.io.jpg`);
          setTimeout(() => {
            downloadButton.disabled = false;
            downloadButton.style = '';
            downloadButtonText.innerText = 'Download';
            sizechart.classList.add('size-chart--border');
          }, 3000);

          // Download Success
          window.gtag('event', 'download', {
            'event_category': 'download_button',
            'event_label': 'Download Success',
            'value': this.filename
          });
        });
    },
    saveAs (uri, filename) {
      const link = document.createElement('a');
      if (typeof link.download === 'string') {
        link.href = uri;
        link.download = filename;

        // Firefox requires the link to be in the body
        document.body.appendChild(link);

        // simulate click
        link.click();

        // remove the link when done
        document.body.removeChild(link);
      } else {
        window.open(uri);
      }
    },
    buildFilename (data) {
      return `${data.brand}_${data.model}`.replaceAll(' ', '');
    }
  }
}
</script>

<style>
#app {
  position: relative;
}

#notification-banner {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: slategray;
  color: white;
  padding: 15px 25px;
  text-align: center;
}
</style>
