// Vuse scripts
import Vue from 'vue';
import Vuse from '../src';
import VueMeta from 'vue-meta';
import VueTour from 'vue-tour'

// sizechart scripts
import './style/_sizechart.styl';
import App from './App.vue';
import Uploader from './Uploader'
import sizechart1 from './sections/sizechart/sizechart1';
require('vue-tour/dist/vue-tour.css')

// add the uploader to the list of sub-components.
Vuse.mix({
  components: {
    Uploader
  }
});

// register components.
Vuse.component(sizechart1);
Vue.use(VueTour);

// seo
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

// install the builder
Vue.use(Vuse, {
  // main css file
  assets: {
    css: 'css/style.css'
  },
  // builder default themes
  themes: [{
    name: 'Create a Size Chart',
    sections: [sizechart1]
  }]
});

export const bus = new Vue();
new Vue({
  el: '#app',
  render: h => h(App)
});
