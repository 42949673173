import { render, staticRenderFns } from "./sizechart1.vue?vue&type=template&id=c7de6842&scoped=true&"
import script from "./sizechart1.vue?vue&type=script&lang=js&"
export * from "./sizechart1.vue?vue&type=script&lang=js&"
import style0 from "./sizechart1.vue?vue&type=style&index=0&id=c7de6842&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7de6842",
  null
  
)

component.options.__file = "sizechart1.vue"
export default component.exports