<template>
  <transition name="fade">
    <div
      v-if="show"
      class="modal">
      <div
        class="modal__backdrop"
        @click="closeModal()"/>

      <div class="modal__dialog">
        <div class="modal__header">
          <slot name="headerContent"/>
          <button
            type="button"
            class="modal__close"
            @click="closeModal()">
            <VuseIcon name="close"/>
          </button>
        </div>

        <div class="modal__body">
          <slot name="bodyContent"/>
        </div>

        <div class="modal__footer">
          <slot name="footerContent"/>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import VuseIcon from './VuseIcon';

export default {
  name: 'Modal',
  components: {
    VuseIcon
  },
  data () {
    return {
      show: false
    };
  },
  methods: {
    closeModal () {
      this.show = false;
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    openModal (isDownloadButton = false) {
      this.show = true;
      document.querySelector('body').classList.add('overflow-hidden');

      if (isDownloadButton) {
        // Download Button Click
        window.gtag('event', 'download_button_click', {
          'event_category': 'download_button_click',
          'event_label': 'Download Button Click'
        });
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.modal
  overflow-x: hidden
  overflow-y: auto
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 309
  background-color: transparent
  box-shadow: none
  margin: 0 auto
  font-family: 'Raleway', Arial
  &__backdrop
    background-color: rgba(0, 0, 0, 0.3)
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    z-index: 301
  &__dialog
    background-color: #ffffff
    position: relative
    width: 100%
    max-width: 900px
    margin: 50px auto
    display: flex
    flex-direction: column
    border-radius: 5px
    z-index: 302
    overflow-y: scroll
    @media screen and (max-width: 992px)
      width: 90%
  &__close
    width: 30px
    height: 30px
    border: none
    background: none
    cursor: pointer
  &__header
    padding: 20px 20px 10px
    display: flex
    align-items: flex-start
    justify-content: space-between
  &__body
    padding: 10px 20px 10px
    overflow: auto
    display: flex
    flex-direction: column
    align-items: stretch
  &__footer
    display: flex
    align-items: center
    justify-content: space-between
    padding: 10px 20px 20px
    &:empty
      padding: 0;
.fade-enter-active,
.fade-leave-active
  transition: opacity 0.2s
.fade-enter,
.fade-leave-to
  opacity: 0
</style>
