<template>
  <div>
    <div
      v-if="$builder.isEditing"
      id="styler"
      ref="styler"
      :class="{ 'is-visible': isVisible }"
      class="styler"
      @click.stop="">
      <ul class="styler-list">
        <template v-if="type === 'block'">
          <li>
            <button
              class="styler-button"
              title="Text Color"
              @click="updateOption('textColor')">
              <VuseIcon name="palettes"/>
            </button>
          </li>
          <li>
            <button
              class="styler-button"
              title="Text Font"
              @click="updateOption('textFont')">
              <VuseIcon name="textFont"/>
            </button>
          </li>
        </template>
        <template v-if="type === 'button' || type === 'section'">
          <li>
            <button
              class="styler-button"
              title="Background Color"
              @click="updateOption('colorer')">
              <VuseIcon name="palettes"/>
            </button>
          </li>
          <li>
            <button
              class="styler-button"
              title="Background Image"
              @click="$refs.backgroundImage.click()">
              <!-- @click="$refs.setBackgroundImage.openModal()"> -->
              <VuseIcon name="image"/>
            </button>
            <input
              ref="backgroundImage"
              type="file"
              name="name"
              style="display: none;"
              @change="onUploadImage('--background-image-url')" >
          </li>
          <li>
            <button
              class="styler-button"
              title="Remove Background Image"
              @click="updateCssVar('--background-image-url', ' '); updateCssVar('--chart-bg-color', 'transparent');">
              <VuseIcon name="noImage"/>
            </button>
          </li>
        </template>
        <template v-if="type === 'logo'">
          <li>
            <button
              class="styler-button logo-upload"
              title="Logo Image"
              @click="$refs.logoImage.click()">
              <VuseIcon name="image"/>
            </button>
            <input
              id="logo-input"
              ref="logoImage"
              type="file"
              name="name"
              style="display: none;"
              @change="onUploadImage('--logo-image-url')" >
          </li>
        </template>
        <li v-if="type === 'button'">
          <button
            class="styler-button"
            @click="updateOption('link')">
            <VuseIcon name="link"/>
          </button>
        </li>
        <!-- <li v-if="type === 'header' || type === 'section'">
        <button
          class="styler-button"
          title="Delete"
          @click="removeSection">
          <VuseIcon name="trash"/>
        </button>
      </li> -->
        <template v-if="type === 'text'">
          <li><button
            class="styler-button"
            title="Text Color"
            @click="updateOption('textColor')">
            <VuseIcon name="palettes"/>
          </button></li>
          <li><button
            class="styler-button"
            title="Text Alignment"
            @click="updateOption('align')">
            <VuseIcon name="align"/>
          </button></li>
          <li><button
            class="styler-button"
            title="Text Style"
            @click="updateOption('textStyle')">
            <VuseIcon name="textStyle"/>
          </button></li>
          <li>
            <button
              class="styler-button"
              title="Text Font"
              @click="updateOption('textFont')">
              <VuseIcon name="textFont"/>
            </button>
          </li>
        </template><template v-if="type === 'grid'">
          <li>
            <button
              class="styler-button"
              @click="selectDevice('mobile')">
              <VuseIcon name="mobile"/>
            </button>
          </li>
          <li>
            <button
              class="styler-button"
              @click="selectDevice('desktop')">
              <VuseIcon name="laptop"/>
            </button>
          </li>
        </template>
      </ul>
      <ul class="styler-list options">
        <li v-if="currentOption === 'colorer'">
          <Chrome v-model="bgColor" />
        </li>
        <li v-if="currentOption === 'textColor' && currentElement === 'headingTextColor'">
          <Chrome v-model="headingTextColor" />
        </li>
        <li v-if="currentOption === 'textColor' && currentElement === 'subHeadingTextColor'">
          <Chrome v-model="subHeadingTextColor" />
        </li>
        <li v-if="currentOption === 'textColor' && currentElement === 'descriptionTextColor'">
          <Chrome v-model="descriptionTextColor" />
        </li>
        <li v-if="currentOption === 'textColor' && currentElement === 'chartTextColor'">
          <Chrome v-model="chartTextColor" />
        </li>
        <li
          v-show="currentOption === 'textFont'"
          class="text-font"/>
        <li v-if="currentOption === 'link'">
          <div class="input-group is-rounded has-itemAfter is-primary"><input
            v-model="url"
            class="input"
            type="text"
            placeholder="type your link" ><button
              class="button"
              @click="addLink">
              <VuseIcon name="link"/>
          </button></div>
        </li>
        <li v-if="currentOption === 'align'">
          <ul class="align">
            <li><button
              class="styler-button"
              @click="execute('justifyleft')">
              <VuseIcon name="left"/>
            </button></li>
            <li><button
              class="styler-button"
              @click="execute('justifycenter')">
              <VuseIcon name="center"/>
            </button></li>
            <li><button
              class="styler-button"
              @click="execute('justifyright')">
              <VuseIcon name="right"/>
            </button></li>
          </ul>
        </li>
        <li v-if="currentOption === 'textStyle'">
          <ul class="align">
            <li><button
              class="styler-button"
              @click="execute('bold')">
              <VuseIcon name="bold"/>
            </button></li>
            <li><button
              class="styler-button"
              @click="execute('italic')">
              <VuseIcon name="italic"/>
            </button></li>
            <li><button
              class="styler-button"
              @click="execute('underline')">
              <VuseIcon name="underline"/>
            </button></li>
          </ul>
        </li>
        <li v-if="currentOption === 'columnWidth'">
          <ul class="align">
            <li><button
              class="styler-button"
              @click="gridValue--">
              <VuseIcon name="arrowLeft"/>
            </button></li>
            <li><input
              v-model="gridValue"
              class="styler-input"
              type="number"
              min="0"
              max="12" ></li>
            <li><button
              class="styler-button"
              @click="gridValue++">
              <VuseIcon name="arrowRight"/>
            </button></li>
          </ul>
        </li>
      </ul>
    </div>
    <Modal ref="setBackgroundImage">
      <template #headerContent>
        <h1>Modal title</h1>
      </template>

      <template #bodyContent>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc sed velit dignissim sodales ut eu sem integer vitae. Id aliquet lectus proin nibh nisl condimentum. Fringilla urna porttitor rhoncus dolor purus. Nam aliquam sem et tortor. Nisl vel pretium lectus quam id. Cras pulvinar mattis nunc sed. Quis ipsum suspendisse ultrices gravida dictum fusce ut placerat orci. Tristique magna sit amet purus. Fermentum et sollicitudin ac orci phasellus egestas tellus. Erat pellentesque adipiscing commodo elit at imperdiet dui accumsan. Felis eget nunc lobortis mattis aliquam faucibus. Tincidunt eget nullam non nisi est sit amet facilisis. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Vitae proin sagittis nisl rhoncus mattis rhoncus urna neque. Eget nunc scelerisque viverra mauris in aliquam sem fringilla ut. Nec nam aliquam sem et tortor consequat id. Commodo nulla facilisi nullam vehicula ipsum a. Elementum tempus egestas sed sed. Faucibus purus in massa tempor nec feugiat nisl pretium fusce.</p>
        <p>Arcu cursus vitae congue mauris rhoncus aenean. Tempor id eu nisl nunc mi. Pharetra diam sit amet nisl suscipit adipiscing bibendum. Ut faucibus pulvinar elementum integer enim. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Eu non diam phasellus vestibulum lorem sed risus. Porttitor rhoncus dolor purus non enim praesent. Sit amet mauris commodo quis imperdiet. Lobortis feugiat vivamus at augue eget. Nibh tellus molestie nunc non blandit. Tellus mauris a diam maecenas sed enim ut. Tortor aliquam nulla facilisi cras fermentum odio eu feugiat pretium. Mattis aliquam faucibus purus in massa.</p>
        <p>Arcu cursus vitae congue mauris rhoncus aenean. Tempor id eu nisl nunc mi. Pharetra diam sit amet nisl suscipit adipiscing bibendum. Ut faucibus pulvinar elementum integer enim. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Eu non diam phasellus vestibulum lorem sed risus. Porttitor rhoncus dolor purus non enim praesent. Sit amet mauris commodo quis imperdiet. Lobortis feugiat vivamus at augue eget. Nibh tellus molestie nunc non blandit. Tellus mauris a diam maecenas sed enim ut. Tortor aliquam nulla facilisi cras fermentum odio eu feugiat pretium. Mattis aliquam faucibus purus in massa.</p>
        <p>Arcu cursus vitae congue mauris rhoncus aenean. Tempor id eu nisl nunc mi. Pharetra diam sit amet nisl suscipit adipiscing bibendum. Ut faucibus pulvinar elementum integer enim. Odio facilisis mauris sit amet massa vitae tortor condimentum lacinia. Eu non diam phasellus vestibulum lorem sed risus. Porttitor rhoncus dolor purus non enim praesent. Sit amet mauris commodo quis imperdiet. Lobortis feugiat vivamus at augue eget. Nibh tellus molestie nunc non blandit. Tellus mauris a diam maecenas sed enim ut. Tortor aliquam nulla facilisi cras fermentum odio eu feugiat pretium. Mattis aliquam faucibus purus in massa.</p>
      </template>

      <template #footerContent>
        <button
          class="controller-button is-visible is-green"
          @click="$refs.setBackgroundImage.closeModal()">Cancel</button>
        <button
          class="controller-button is-visible is-green"
          @click="$refs.setBackgroundImage.closeModal()">Save</button>
      </template>
    </Modal>
  </div>
</template>

<script>
import Popper from 'popper.js';
import VuseIcon from './VuseIcon';
import Modal from './Modal';
import { isParentTo } from './../util';
import { Chrome } from 'vue-color';
import { bus } from '../../sizechart/app';

export default {
  name: 'Styler',
  components: {
    VuseIcon,
    Chrome,
    Modal
  },
  props: {
    el: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    section: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    bgColor: '',
    headingTextColor: '',
    subHeadingTextColor: '',
    descriptionTextColor: '',
    chartTextColor: '',
    oldColorerColor: '',
    colorerColor: '',
    mouseTarget: '',
    currentOption: '',
    currentElement: '',
    url: '',
    gridValue: 0,
    isVisible: false
  }),
  watch: {
    colorerColor: function () {
      this.changeColor();
    },
    bgColor: function () {
      this.updateCssVar('--background-color', this.bgColor.hex);
    },
    headingTextColor: function () {
      this.updateCssVar('--heading-text-color', this.headingTextColor.hex);
    },
    subHeadingTextColor: function () {
      this.updateCssVar('--subheading-text-color', this.subHeadingTextColor.hex);
    },
    descriptionTextColor: function () {
      this.updateCssVar('--description-text-color', this.descriptionTextColor.hex);
    },
    chartTextColor: function () {
      this.updateCssVar('--chart-text-color', this.chartTextColor.hex);
      this.updateCssVar('--chart-bg-color', this.getContrast(this.chartTextColor.hex));
    },
    gridValue: function () {
      this.gridValue = Math.min(Math.max(this.gridValue, 0), 12);
      this.section.set(this.name, (grid) => {
        grid[this.device] = this.gridValue;
      });
    }
  },
  created () {
    if (this.type === 'button') {
      this.url = this.section.get(`${this.name}.href`);
      this.el.contentEditable = 'true';
    }
    if (this.type === 'text') {
      this.el.contentEditable = 'true';
    }

    bus.$on('editing-element', data => {
      if (data.classList.value.includes('-heading')) {
        this.currentElement = 'headingTextColor';
      }

      if (data.classList.value.includes('-subheading')) {
        this.currentElement = 'subHeadingTextColor';
      }

      if (data.classList.value.includes('-description')) {
        this.currentElement = 'descriptionTextColor';
      }

      if (data.classList.value.includes('table-wrapper') || data.classList.value === '') {
        this.currentElement = 'chartTextColor';
      }
    });

    bus.$on('dragstart', () => {
      this.hideStyler();
    });

    bus.$on('dragend', () => {
      this.showStyler();
    });

    bus.$on('theme-updated', data => {
      this.updateCssVar('--background-image-url', data.imageUrl);
      this.updateCssVar('--chart-bg-color', this.getContrast('black'));
    })
  },
  mounted () {
    if (!this.$builder.isEditing) return;

    // add listeners to show/hide styler
    this.el.addEventListener('click', this.showStyler);
  },
  beforeDestroy () {
    this.hideStyler();
    this.$refs.styler.remove();
    this.el.classList.remove('is-editable');
    this.el.removeEventListener('click', this.showStyler);
    document.removeEventListener('click', this.hideStyler, true);
  },
  methods: {
    updateOption (option) {
      this.currentOption = option;
      this.$nextTick(() => {
        this.popper.update();
      })
    },
    updateCssVar (key, value) {
      document.documentElement.style.setProperty(key, value);
    },
    addLink () {
      this.section.set(`${this.name}.href`, this.url);
    },
    changeColor () {
      this.removeClass(`is-${this.oldColorerColor}`);
      this.oldColorerColor = this.colorerColor;
      this.addClass(`is-${this.colorerColor}`);
    },
    onUploadImage (cssVar) {
      // getting a hold of the file reference
      const file = event.target.files[0];
      const isLogo = event.target.id === 'logo-input';

      // setting up the reader
      const reader = new FileReader();
      reader.readAsDataURL(file);

      // here we tell the reader what to do when it's done reading...
      reader.onload = readerEvent => {
        const imageUrl = readerEvent.target.result;
        if (isLogo) {
          document.getElementById('logo').src = imageUrl;
        } else {
          this.src = imageUrl;
          this.updateCssVar(cssVar, `url('${imageUrl}')`);
          this.updateCssVar('--chart-bg-color', this.getContrast('black'));
        }
      }
    },
    addClass (className) {
      this.section.set(this.name, (value) => {
        if (value && value.classes && Array.isArray(value.classes)) {
          value = value.classes;
        }
        value.push(className);
      });
    },
    selectDevice (device) {
      const gridValue = this.section.get(this.name)[device];
      this.updateOption('columnWidth');
      this.device = device;
      this.gridValue = gridValue;
    },
    removeClass (className) {
      if (Array.isArray(className)) {
        return className.forEach(c => {
          this.removeClass(c);
        });
      }
      this.section.set(this.name, value => {
        if (value && value.classes && Array.isArray(value.classes)) {
          value = value.classes;
        }
        const idx = value.indexOf(className);
        value.splice(idx, 1);
      });
    },
    removeSection () {
      this.$builder.remove(this.section);
    },
    execute (command, value = null) {
      this.el.focus();
      document.execCommand(command, false, value);
    },
    showStyler (event) {
      if (event) {
        event.stopPropagation();
      }

      if (this.isVisible) return;
      this.isVisible = true;

      // exute popper element
      if (!this.popper) {
        const position = this.$props.type === 'section' ? 'left-start' : 'top';
        this.popper = new Popper(this.el, this.$refs.styler, {
          placement: position
        });
      }
      document.addEventListener('click', this.hideStyler, true);
      this.currentOption = '';
    },
    hideStyler (event) {
      if (
        (event && isParentTo(event.target, this.$el)) ||
        (event && isParentTo(event.target, this.el))
      ) {
        return
      }
      this.isVisible = false;
      if (this.popper) {
        this.popper.destroy();
        this.popper = null;
      }
      document.removeEventListener('click', this.hideStyler, true);

      if (this.type === 'section' || this.type === 'grid') {
        return;
      }
      if (this.type === 'button') {
        this.section.set(`${this.name}.text`, this.el.innerHTML);
        return;
      }

      this.section.set(this.name, this.el.innerHTML);
    },
    getContrast (hexcolor) {
      // If a leading # is provided, remove it
      if (hexcolor.slice(0, 1) === '#') {
        hexcolor = hexcolor.slice(1);
      }

      // If a three-character hexcode, make six-character
      if (hexcolor.length === 3) {
        hexcolor = hexcolor.split('').map(function (hex) {
          return hex + hex;
        }).join('');
      }

      // Convert to RGB value
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);

      // Get YIQ ratio
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

      // Check contrast
      return (yiq >= 128) ? 'rgba(0,0,0,0.5)' : 'rgba(255,255,255,0.5)';
    }
  }
};
</script>

<style lang="stylus">
@import '~@baianat/base.framework/src/stylus/util/colors'

:root
  --background-image-url: ' '
  --background-image-align: center
  --background-color: white
  --heading-text-color: black
  --subheading-text-color: black
  --description-text-color: black
  --chart-text-color: black
  --chart-bg-color: transparent
  --mockup-image-url: ''
  --logo-image-url: ''

.styler
  position: absolute
  top: 0
  z-index: 200
  visibility: hidden
  opacity: 0
  margin: 10px 0
  padding: 5px
  background: $dark
  border-radius: 10px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  &-list
    display: flex
    justify-content: center
    align-items: center
    list-style: none
    margin: 0
    padding: 0
  &-input
    background: $white
    color: $dark
    cursor: pointer
    border: 0
    outline: 0
    width: 40px
    height: 40px
    border-radius: 42px
    margin: 0 5px 0 0
    text-align: center
    -webkit-appearance: none
    -moz-appearance: textfield
    appearance: none
  &-button
    display: flex
    justify-content: center
    align-items: center
    outline: 0
    background: $dark
    border: 0
    fill: $white
    color: $white
    width: 42px
    height: 42px
    border-radius: 10px
    cursor: pointer
    &:hover
      background: darken($dark, 20%)
  &-selector
    margin: 0 5px
  &.is-visible
    visibility: visible
    opacity: 1
  .input-group
    margin: 5px

.align
  @extend .styler-list
  height: 42px

.colorer
  @extend .styler-list
  height: 42px
  li >input
    -webkit-appearance: none
    -moz-appearance: textfield
    appearance: none
    cursor: pointer
    width: 30px
    height: 30px
    border-radius: 40px
    border: 4px solid darken($dark, 20%)
    margin: 0 5px
    outline: none
    &:checked
      border-color: lighten($dark, 20%)
    &:hover
      border-color: lighten($dark, 20%)
    &#colorRed
      background $red
    &#colorBlue
      background $blue
    &#colorGreen
      background $green
    &#colorBlack
      background $black
    &#colorWhite
      background $white

.is-hidden
  display: none

input[type=number]::-webkit-inner-spin-button
input[type=number]::-webkit-outer-spin-button
  -webkit-appearance: none
  margin: 0
</style>
