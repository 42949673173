<template>
  <div
    ref="uploaderWrapper"
    class="uploader">
    <img
      ref="image"
      :id="imageId"
      :src="src">
    <input
      v-if="$builder.isEditing && mode === 'input'"
      ref="uploader"
      class="uploader-input"
      type="file"
      @change="updateImage">
  </div>
</template>

<script>
import { bus } from '../sizechart/app';

export default {
  name: 'Uploader',
  inject: ['$builder', '$section'],
  props: {
    path: {
      type: String,
      required: true
    },
    mode: {
      default: 'input',
      type: String
    },
    imageId: {
      default: '',
      type: String
    }
  },
  data: () => ({
    src: ''
  }),
  created () {
    this.src = this.path;

    bus.$on('brand-updated', data => {
      if (this.$refs.uploaderWrapper.classList.contains('size-chart__mockup')) {
        this.src = data.image;
      }
    });
  },
  methods: {
    updateImage () {
      const file = this.$refs.uploader.files[0];
      if (!file) return;
      const imageURL = URL.createObjectURL(file);

      if (this.imageId === 'logo') {
        this.$refs.image.$el.src = this.getLogoImageUrl();
      } else {
        this.src = imageURL;
      }

      this.$section.set(this.path, imageURL);
    },
    getLogoImageUrl () {
      return getComputedStyle(document.documentElement).getPropertyValue('--logo-image-url') && getComputedStyle(document.documentElement).getPropertyValue('--logo-image-url').length > 10 ? getComputedStyle(document.documentElement).getPropertyValue('--logo-image-url') : false;
    }
  }
}
</script>

<style lang="stylus">
@import '../src/stylus/_app.styl'

.uploader
  position: relative
  outline: none
  &-input
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    width: 100%
    height: 100%
    opacity: 0
    z-index: 100
    cursor: pointer
  >img
    width: 100%
    display: block
  &:hover
    box-shadow: 0 0 0 2px $blue
  &.move > img
    cursor: move;
    &:hover
      box-shadow: 0 0 0 2px #0072ff;
</style>
