<template>
  <div
    ref="home"
    class="artboard-container">
    <div
      id="artboard"
      ref="artboard"
      :class="{ 'is-sorting': $builder.isSorting, 'is-editable': $builder.isEditing }"
      class="artboard">
      <component
        v-for="section in $builder.sections"
        :is="section.name"
        :key="section.id"
        :id="section.id"/>
    </div>
    <div class="controller">
      <div
        class="controller-intro">
        <img
          class="logo"
          src="../../sizechart/img/logo-white.svg">
        <div class="controller-intro__top-content">
          <h1>Create free customizable size charts for your brand.</h1>
          <br>
          <VueTyper
            :text="[
              'Reduce returns by up to 47%.',
              'Give customers confidence your items will fit.',
              'It\'s free. No sign up required.'
            ]"
            :repeat="0"
            :shuffle="false"
            :pre-type-delay="75"
            :type-delay="75"
            :pre-erase-delay="750"
            :erase-delay="750"
            :erase-on-complete="false"
            initial-action="typing"
            erase-style="select-all"
            caret-animation="blink"
          />
          <div class="controller-themes">
            <a
              class="controller-theme"
              href="/builder">Create a Size Chart</a>
          </div>
        </div>
        <div class="video-wrapper">
          <video
            src="../../sizechart/videos/sizechart.io-demo-compressed.mp4"
            poster="../../sizechart/videos/sizechart.io-demo-poster.jpg"
            type="video/mp4"
            muted
            playsinline
            autoplay
            loop />
        </div>
        <div class="controller-intro__divider-container">
          <div class="controller-intro__divider-container__content">
            <ul>
              <li>
                <span class="emoji">🎁</span> Add your own custom logo
              </li>
              <li>
                <span class="emoji">🎈</span> Add a background image
              </li>
              <li>
                <span class="emoji">🎉</span> Customize fonts, colors and more
              </li>
            </ul>
          </div>
        </div>
        <div class="content">
          <h2>Why Use Sizechart.io's Free Size Chart Builder?</h2>
          <ul style="font-size: 20px;">
            <li><strong>It's Free. No sign up required!</strong></li>
            <li>Ensure precision with our meticulously crafted templates.</li>
            <li>Tailor size charts to match your brand’s unique specifications.</li>
            <li>Simple, intuitive design for quick and easy chart creation.</li>
          </ul>
        </div>
        <div class="content">
          <h2>How it Works</h2>
          <ol style="font-size: 20px;">
            <li><strong>Select a Template:</strong> Choose from a variety of professionally designed size chart templates.</li>
            <li><strong>Customize:</strong> Input your specific measurements and brand details.</li>
            <li><strong>Save and Share:</strong> Download your size chart or embed it directly into your website.</li>
          </ol>
        </div>
        <div class="content">
          <h2>Real User Testimonials</h2>
          <ul style="font-size: 20px;">
            <li>“The free size chart builder is a game-changer for my online store!” - Jane D.</li>
            <li>“Easy to use and extremely accurate. Highly recommended!” - Alex P.</li>
            <li>“I love how customizable the charts are. Perfect for my brand.” - Lisa M.</li>
          </ul>
        </div>
        <div class="content">
          <h2>Frequently Asked Questions</h2>
          <ul style="font-size: 20px;">
            <li><strong>What is the Free Size Chart Builder?</strong> A tool by SizeChart.io that lets you create precise and customizable size charts for free.</li>
            <li><strong>How do I use it?</strong> Simply use a template or customize your own with your custom images, font styles, colors and logo. Then, download or share your chart.</li>
            <li><strong>Is it really free?</strong> Yes, the size chart builder is completely free to use.</li>
          </ul>
        </div>
        <div class="controller-intro__bottom-content">
          Made with ❤️ by <a
            href="https://www.mcarlucci.com"
            target="_blank">Matt Carlucci</a>
          &nbsp;|&nbsp;
          <a
            href="privacy.html"
            target="_blank"
            style="color: #000">Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueTyper } from 'vue-typer';

export default {
  name: 'Home',
  components: {
    VueTyper
  }
  // mounted () {
  //   this.$nextTick(() => {
  //     if (!window.location.pathname.includes('builder')) {
  //       // Buy me a coffee
  //       let bmacscript = document.createElement('script');
  //       bmacscript.setAttribute('type', 'application/javascript');
  //       bmacscript.setAttribute('data-cfasync', 'false');
  //       bmacscript.setAttribute('data-name', 'BMC-Widget');
  //       bmacscript.src = 'https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js';
  //       bmacscript.setAttribute('data-id', 'mcarlucci');
  //       bmacscript.setAttribute('data-description', 'Support me on Buy me a coffee!');
  //       bmacscript.setAttribute('data-message', 'Hi, I\'m Matt. This site is built and maintained by me in my spare time. All donations are welcome :)');
  //       bmacscript.setAttribute('data-color', '#40DCA5');
  //       bmacscript.setAttribute('data-position', 'Right');
  //       bmacscript.setAttribute('data-x_margin', '18');
  //       bmacscript.setAttribute('data-y_margin', '18');

  //       // Append the bmacscript element to the document
  //       document.head.appendChild(bmacscript);
  //     }
  //   })
  // }
};
</script>

<style scoped>
.content {
  width: 100%;
  max-width: 800px;
  margin-bottom: 50px;
}

.content ul {
  font-size: 20px;
}

.content li {
  margin-bottom: 12px;
}
</style>
