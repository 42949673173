import { render, staticRenderFns } from "./Uploader.vue?vue&type=template&id=72bff78a&"
import script from "./Uploader.vue?vue&type=script&lang=js&"
export * from "./Uploader.vue?vue&type=script&lang=js&"
import style0 from "./Uploader.vue?vue&type=style&index=0&lang=stylus&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "Uploader.vue"
export default component.exports